import cmsUrlBase from './cmsUrlBase';

export default (hostName, stage, isDeployed) => {
    let oneLni = null;
    let ewn = null;

    const cms = {
        api: `${cmsUrlBase(stage)}api/vtl`,
    };

    if (isDeployed) {
        oneLni = {
            root: `https://${hostName}/one-lni/v3`,
            script: `https://${hostName}/one-lni/v3/oneLni.js`,
            style: `https://${hostName}/one-lni/v3/oneLni.css`,
            polyfills: `https://${hostName}/one-lni/v3/polyfills.js`,
        };

        ewn = {
            script: `https://${hostName}/ewn/v4/ewn.js`,
            style: `https://${hostName}/ewn/v4/ewnFrame.css`,
            legacy3Css: `https://${hostName}/ewn3`,
            legacy31Css: `https://${hostName}/ewn3branch`,
        };
    } else {
        oneLni = {
            root: `http://dev-secure.lni.wa.gov/one-lni/v3/`,
            script: `http://dev-secure.lni.wa.gov/one-lni/v3/oneLni.js`,
            style: `http://dev-secure.lni.wa.gov/one-lni/v3/oneLni.css`,
            polyfills: `https://dev-secure.lni.wa.gov/one-lni/v3/polyfills.js`,
        };

        ewn = {
            script: `http://localhost:8080/ewn.js`,
            style: `http://localhost:8080/ewnFrame.css`,
            legacy3Css: 'https://dev-secure.lni.wa.gov/ewn3',
            legacy31Css: 'https://dev-secure.lni.wa.gov/ewn3branch',
        };
    }

    return {
        cms,
        oneLni,
        ewn,
    };
};