// eslint-disable-next-line no-mixed-operators
/* eslint-disable no-mixed-operators */
const defaultConfiguration = {
    // authenticated|anonymous|simple - which environment is the user in, which header do they get?
    experience: 'anonymous',

    // object - which scripts the contained app wants loaded for it
    legacyScripts: {
        backbone: true,
        jquery: true,
        modernizr: true,
        require: true,
        underscore: true,
    },
    // true|false - where or not there is a breadcrumb
    breadcrumb: null,

    // cms|null - the variant of left navigation
    secondaryNavigation: null,

    // string - the app's location relative to the cms IA
    iaLocation: null,

    // auto | webcomponent | iframe - the method used to separate EWN from the app
    isolation: 'auto',

    // true | false - whether this app had used the EWN 3.1 version
    is31App: false,

    // object - information about the app used by EWN
    appInformation: null,

    // true | false - whether this app was initiated via the ewnHeader.js file
    compatibilityMode: false,

    // true | false - whether this app(used primarily by popup pages in OHMS) should
    // hide all of the EWN components
    hideHeader: false,

    // true | false - whether the sharing widget should be displayed
    sharingWidget: false,
};

export default options => {
    const configuration = {
        original: {
            ...defaultConfiguration,
            ...options,
        },
        show: {},
    };

    configuration.compatibilityMode = configuration.original.compatibilityMode;

    // By default, include all legacy scripts.  If there is a config object,
    // let it override this default.
    if (configuration.original.legacyScripts) {
        configuration.legacyScripts = configuration.original.legacyScripts;
    }

    // This is used by OHMS for popups
    configuration.hideEwnComponents = configuration.original.hideHeader;

    if (configuration.hideEwnComponents) {
        configuration.show.publicHeader = false;
        configuration.show.secureHeader = false;
        configuration.show.simpleHeader = false;
        // We need this setting directly because it is clearer to pass this
        // string into the webcomponent than to pass 3 booleans.
        configuration.experience = configuration.original.experience;
        configuration.show.functionalFooter = false;
        configuration.show.secondaryNavigation = false;
        configuration.show.breadcrumb = false;
    } else {
        configuration.show.publicHeader = configuration.original.experience === 'anonymous';
        configuration.show.secureHeader = configuration.original.experience === 'authenticated';
        configuration.show.simpleHeader = configuration.original.experience === 'simple';

        // We need this setting directly because it is clearer to pass this
        // string into the webcomponent than to pass 3 booleans.
        configuration.experience = configuration.original.experience;

        configuration.show.functionalFooter = configuration.original.experience === 'anonymous';

        configuration.show.secondaryNavigation
            = configuration.original.secondaryNavigation === 'cms';
        configuration.show.breadcrumb = configuration.original.breadcrumb;
    }

    if (configuration.compatibilityMode) {
        // If neither the left nav nor breadcrumb is shown, we will not set this and so
        // the navigation components will not try to place the user and
        // highlight tabs on the menu, etc.
        configuration.iaLocation
            = configuration.show.secondaryNavigation || configuration.show.breadcrumb
                ? configuration.original.appInformation.iaLocation
                : '/';
    } else {
        configuration.iaLocation = configuration.original.iaLocation;
    }

    if (configuration.compatibilityMode) {
        configuration.is31App = configuration.original.appInformation.is31App;
        configuration.currentAppPath = configuration.original.appInformation.currentAppPath;
        configuration.appPaths = configuration.original.appInformation.appPaths;

        configuration.appName = configuration.original.appInformation.legacyName;
        configuration.appTitle = configuration.original.appInformation.legacyTitle;
    }

    configuration.useWebComponents = false;
    if (!configuration.original.isolation || configuration.original.isolation === 'auto') {
        configuration.useWebComponents
            = document.head.attachShadow && customElements
            || configuration.original.isolation === 'component';
    } else {
        configuration.useWebComponents = configuration.original.isolation === 'component';
    }
    if (configuration.original.sharingWidget) {
        configuration.sharingWidget = configuration.original.sharingWidget;
    }

    return configuration;
};