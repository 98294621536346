import environment from '../../core/environment/environment';
import initializeOneLni from '../../core/lifecycle/initializeOneLni';
import populateContainer from '../../core/html-building/populateContainer';
import addOneLniScripts from '../../core/html-building/addOneLniScripts';
import addStyleLink from '../../core/html-building/addStyleLink';

const createHtmlHeader = (configuration, children, oneLniScriptElement) => {
    const isolationContainer = document.createElement('div');
    isolationContainer.id = 'oneLniIsolationHeaderContainer';
    isolationContainer.classList.add('one-lni-isolation-container');
    populateContainer(isolationContainer, children);

    oneLniScriptElement.addEventListener('load', () => {
        initializeOneLni(
            '#oneLniIsolationHeaderContainer',
            null,
            configuration.iaLocation
        );
    });
    return isolationContainer;
};

export default configuration => {
    const oneLniScripts = addOneLniScripts(document.head, false);
    addStyleLink(document.head, {
        type: 'stylesheet',
        href: `${environment.oneLni.root}/isolatedOneLniStyles.css`,
    });

    let container;
    if (configuration.show.publicHeader) {
        const children = [
            'lni-header-anonymous',
        ];
        if (configuration.show.breadcrumb) {
            children.push('lni-breadcrumb');
        }

        container = createHtmlHeader(configuration, children, oneLniScripts.scriptElement);

    } else if (configuration.show.secureHeader) {
        container = createHtmlHeader(configuration, [
            'lni-header-secure',
        ], oneLniScripts.scriptElement);
    } else {
        container = createHtmlHeader(configuration, [
            'lni-header-simple',
        ], oneLniScripts.scriptElement);

        if (configuration.appTitle) {
            let component = container.querySelector('lni-header-simple');
            component.setAttribute('app-name', configuration.appTitle);
        }
    }

    return container;
};