import footer from '../../elements/lni-ewn-footer';
import header from '../../elements/lni-ewn-header';
import secondaryNav from '../../elements/lni-ewn-secondary-nav';

export default (configuration, callback) => {
    // Below, we add the actual EWN components into the containing elements that
    // we created above.
    if (!configuration.hideEwnComponents) {
        if (configuration.show.secondaryNavigation) {
            secondaryNav(configuration);
        }

        footer(configuration);
        header(configuration);
    }
    // We're done building EWN, give control back to the EWNHeader script, if it needs it.
    callback && callback();
};