import createIframeContainer from '../../core/html-building/createIframeContainer';
import addOneLniScripts from '../../core/html-building/addOneLniScripts';
import compileIframe from '../../core/html-building/compileIframe';


export default (iframeElement, configuration) => {
    const headElement = iframeElement.contentDocument.head;
    addOneLniScripts(headElement, true);

    const bodyElement = iframeElement.contentDocument.body;

    const container = createIframeContainer(bodyElement, 'ewn-footer', [
        'lni-footer',
    ]);

    if (!configuration.show.functionalFooter) {
        container.classList.add('is-legal-footer');
    }

    return compileIframe(iframeElement, container, null);
};