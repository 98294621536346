export default function detect() {
    let stage = 'production';
    if (window.location.host.indexOf('unit-test.wads.wa.gov', 0) !== -1
        || window.location.host.indexOf('dev.wads.wa.gov', 0) !== -1
        || window.location.host.indexOf('localhost', 0) !== -1
        || window.location.host.indexOf('dev-secure.lni.wa.gov', 0) !== -1
        || window.location.host.indexOf('dev2-secure.lni.wa.gov', 0) !== -1
        || window.location.host.indexOf('lnidaptumcom91', 0) !== -1) {
        stage = 'integration';
    } else if (window.location.host.indexOf('test-internet.lni.wa.gov', 0) !== -1
        || window.location.host.indexOf('test-secure.lni.wa.gov', 0) !== -1
        || window.location.host.indexOf('test-fortress.wa.gov', 0) !== -1
        || window.location.host.indexOf('lnixapolycom9pr', 0) !== -1
        || window.location.host.indexOf('sowa.stage.sumtotal.host', 0) !== -1
        || window.location.host.indexOf('test-drivers-complaint.lni.wa.gov', 0) !== -1
        || window.location.host.indexOf('wordbridge.io') !== -1) {
        stage = 'pre-production';
    }

    return stage;
}