import LniEwnFooter from './LniEwnFooter';
import footerIframe from './footerIframe';

export default configuration => {
    const footerContainer = configuration.compatibilityMode
        ? document.querySelector('#footer')
        : document.body;

    if (configuration.useWebComponents) {
        customElements.define('lni-ewn-footer', LniEwnFooter);
        const footerElement = document.createElement('lni-ewn-footer');
        footerElement.setAttribute('is-functional-footer', configuration.show.functionalFooter);

        footerContainer.appendChild(footerElement);
    } else {
        const footerElement = document.createElement('IFRAME');
        footerElement.id = 'ewn-footer-iframe';
        if (!configuration.show.functionalFooter) {
            footerElement.classList.add('is-legal-footer');
        }
        footerContainer.appendChild(footerElement);
        footerIframe(footerElement, configuration);
    }
};