import environment from '../../environment/environment';

export default (head, configuration) => {
    // This sets the font size on the html element. The webcomponents that use the
    // OneLni styles require this because they use rem units
    const htmlElement = document.querySelector('HTML');
    htmlElement.style.fontSize = '18px';

    const iframeStyle = configuration.compatibilityMode ? '' : `
#ewn-footer-iframe {
    border: 0;
    width: 100%;
    height: 455px;
}

#ewn-footer-iframe.is-legal-footer {
    height: 190px;
}
    `;

    let styleElement = document.createElement('style');
    styleElement.innerHTML = `
@font-face {
    font-family: 'MyLNI';
    src: url('${environment.oneLni.root}/fonts/MyLNI.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body, html {
    margin: 0
}

${iframeStyle}
    `;

    head.appendChild(styleElement);
};