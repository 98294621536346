import addInlineStyle from './addInlineStyle';
import addLegacyStylesheets from './addLegacyStylesheets';
import addPageResources from './addPageResources';
import configureHostApplicationContainer from './configureHostApplicationContainer';

export default {
    addInlineStyle,
    addLegacyStylesheets,
    addPageResources,
    configureHostApplicationContainer,
};