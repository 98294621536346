import environment from '../../environment/environment';
import addStyleLink from '../addStyleLink';

export default (head, configuration) => {
    const location = configuration.is31App
        ? environment.ewn.legacy31Css
        : environment.ewn.legacy3Css;
    addStyleLink(head, {
        type: 'stylesheet',
        href: `${location}/css/ewn.css`,
    });

    addStyleLink(head, {
        type: 'stylesheet',
        href: `${location}/css/ewnAdapt.css`,
    });

    addStyleLink(head, {
        type: 'stylesheet',
        href: `${location}/_WebNavCommon/CSS/contentAdapt.css`,
    });

    addStyleLink(head, {
        type: 'stylesheet',
        href: `${location}/_WebNavCommon/CSS/content.css`,
    });
};