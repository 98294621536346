import configure from './configure';
import containingPage from '../html-building/containing-page';
import addComponents from './addComponents';


import '../../ewnFrame.css';

export const start = (options, callback) => {
    const configuration = configure(options);

    // This adds fonts, old ewn stylesheets, and the new ewnFrame sheet to the main document
    // The fonts must be here in the main document for webcomponents to have that font.
    containingPage.addPageResources(configuration);

    if (configuration.compatibilityMode) {
        // This builds the DOM structure for the EWN app, including the DOM
        // container that will hold the contained app.
        containingPage.configureHostApplicationContainer(configuration);
        addComponents(configuration, callback);
    } else {
        addComponents(configuration, callback);
    }
};

window.ewn = {
    start,
};