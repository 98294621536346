import environment from '../environment/environment';
import addScriptLink from './addScriptLink';
import addStyleLink from './addStyleLink';

// This will add the onelni script and css.  We don't ever want to add the full
// one-lni css file to the main document or it will overwrite the contained apps css.
// For the main document, if needed, we add one-lni styles via the "isolatedOneLniStyles.css"
export default (parentElement, includeCss) => {
    const scriptElement = addScriptLink(parentElement, {
        src: environment.oneLni.script,
        id: 'oneLniScript',
    });

    parentElement.appendChild(scriptElement);

    let styleElement = null;
    if (includeCss) {
        let styleElement = addStyleLink(parentElement, {
            type: 'stylesheet',
            href: environment.oneLni.style,
        });
        parentElement.appendChild(styleElement);
    }

    return {
        scriptElement,
        styleElement,
    };
};