export default (containerElement, windowContext, iaLocation) => {
    const context = windowContext || window;
    const framework = context.activeOneLni();
    if (iaLocation) {
        framework.setLocation(iaLocation);
    }

    framework.initialize({
        el: containerElement,
        enableRouter: false,
    }, () => framework.start(() => {
        // eslint-disable-next-line no-console
        console.log('Framework initialized');
    }));
};