import environment from '../environment/environment';

const addIframeResources = headElement => {
    const fontElement = document.createElement('link');
    fontElement.rel = 'stylesheet';
    fontElement.href = 'https://fonts.googleapis.com/css?family=Libre+Franklin:400,400i,700,700i,900|Roboto:400,500,700,900';
    headElement.appendChild(fontElement);

    let styleElement = document.createElement('style');
    styleElement.innerHTML = `
        @font-face {
            font-family: 'MyLNI';
            src: url('${environment.oneLni.root}/fonts/MyLNI.woff') format('woff');
            font-weight: normal;
            font-style: normal;
        }

        .is-legal-footer .lni-c-site-footer__container--functional {
            display: none;
        }

        #oneLniIframe.is-legal-footer {
            margin-top: 20px;
        }
    `;

    headElement.appendChild(styleElement);
};

// This adds a string literal that will evaluate as a script.  This is not run
// through babel(transpiled) so it must be javascript can natively run on IE>=11
const addInitializationScript = (headElement, iaLocation) => {
    const oneLniInitializationScriptElement = document.createElement('script');
    const scriptText = `
                            function initializeFramework() {
                                var framework = window.activeOneLni();
                                var containerElement = document.getElementById('oneLniIframe');
                                if ('${iaLocation}') {
                                    framework.setLocation('${iaLocation}');
                                }

                                framework.initialize({
                                    el: containerElement,
                                    enableRouter: false,
                                }, function() {
                                    framework.start(function() {
                                        console.log('Framework initialized');
                                    });
                                });
                            }

                            var addLinkHandler = function(link) {
                                link.addEventListener('click', function (e) {
                                    e.preventDefault();
                                    window.parent.location = link.href;
                                });
                            }

                            var addLinkHandlers = function(allLinks) {
                                for (var i = 0; i < allLinks.length; i++) {
                                    var link = allLinks[i];
                                    addLinkHandler(link);
                                }
                            };

                            var script = document.querySelector('#oneLniScript');
                            script.addEventListener('load', function() {
                                window.initializeFramework();

                                var setLinksInterval = setInterval(function() {
                                    var allLinks = document.querySelectorAll('a');
                                    if (allLinks.length !== 0) {
                                        clearInterval(setLinksInterval);
                                        addLinkHandlers(allLinks);
                                    }
                                }, 500);
                            });
                        `;

    const scriptTextNode = document.createTextNode(scriptText);
    oneLniInitializationScriptElement.appendChild(scriptTextNode);
    headElement.appendChild(oneLniInitializationScriptElement);
};

const configureIframe = iframeElement => {
    iframeElement.sandbox = 'allow-top-navigation allow-scripts allow-forms';
};


export default (iframeElement, container, iaLocation) => {
    configureIframe(iframeElement);

    const headElement = iframeElement.contentDocument.head;
    addIframeResources(headElement);
    addInitializationScript(headElement, iaLocation);

    const bodyElement = iframeElement.contentDocument.body;
    bodyElement.appendChild(container);

    return iframeElement;
};