export default (parentElement, id, children) => {
    const container = document.createElement('body');
    container.id = id;

    let idCounter = 0;

    children.forEach(child => {
        const childElement = document.createElement(child);
        childElement.id = `${id}-${child}_${idCounter++}`;

        container.appendChild(childElement);
    });

    parentElement.appendChild(container);

    return container;
};