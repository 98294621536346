import LniEwnHeader from './LniEwnHeader';
import lniEwnHtmlHeader from './LniEwnHtmlHeader';

export default (configuration, oneLniScriptElement) => {
    let headerElement = null;
    const headerContainer = document.querySelector('#ewnHeaderContainer');

    if (configuration.useWebComponents) {
        customElements.define('lni-ewn-header', LniEwnHeader);
        headerElement = document.createElement('lni-ewn-header');
        headerElement.setAttribute('experience', configuration.experience);
        headerElement.setAttribute('breadcrumb', configuration.show.breadcrumb);
        headerElement.setAttribute(
            'ia-location',
            configuration.iaLocation,
        );

        if (configuration.compatibilityMode) {
            headerElement.setAttribute('app-title', configuration.appTitle);
            headerElement.setAttribute('app-names', configuration.appPaths.join(','));
        }

        if (configuration.compatibilityMode) {
            headerContainer.appendChild(headerElement);
        } else {
            document.body.insertBefore(headerElement, document.body.firstChild);
        }
    } else {
        const headerElement = lniEwnHtmlHeader(configuration, oneLniScriptElement);

        if (configuration.compatibilityMode) {
            headerContainer.appendChild(headerElement);
        } else {
            document.body.insertBefore(headerElement, document.body.firstChild);
        }
    }
};