import initializeOneLni from '../../core/lifecycle/initializeOneLni';
import createBodyContainer from '../../core/html-building/createBodyContainer';
import addOneLniScripts from '../../core/html-building/addOneLniScripts';

export default class LniEwnFooter extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({
            mode: 'open',
        });

        this.shadow = shadow;
    }
    connectedCallback() {
        this.initializeFooter(this);
    }

    initializeFooter() {
        const oneLniElements = addOneLniScripts(this.shadow, true);
        let styleElement = document.createElement('style');
        styleElement.innerHTML = `
            .is-legal-footer .lni-c-site-footer__container--functional {
                display: none;
            }
        `;

        this.shadow.appendChild(styleElement);

        const isFunctionalFooter = this.getAttribute('is-functional-footer');

        const container = createBodyContainer(this.shadow, 'ewn-footer', [
            'lni-footer',
        ]);

        if (isFunctionalFooter === 'false') {
            container.classList.add('is-legal-footer');
        }

        oneLniElements.scriptElement.addEventListener('load', () => {
            initializeOneLni(container);
        });
    }
}