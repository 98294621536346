import detect from './detect';
import hostName from './hostName';
import urls from './urls';

// To run locally:
// Change "isDeployed" to false(below)
// Run htt-server from ewn/deploy on 8080
// Run http-server onelni/deploy/dist on 8082 using this command
//     http-server –p 8082 --cors

// TODO: get this from the environment/build
let isDeployed = true;
const stage = detect();
const host = hostName(stage);
const environmentUrls = urls(host, stage, isDeployed);
export default  {
    stage,
    cms: environmentUrls.cms,
    oneLni: environmentUrls.oneLni,
    ewn: environmentUrls.ewn,
    description: stage,
};