import LniSecondaryNav from './LniEwnSecondaryNav';
import secondaryNavIframe from './secondaryNavIframe';

export default configuration => {

    //TODO: Ad support for left nav outside of compatibility mode.
    let secondaryNavElement = null;
    const ewnLeftMenuContainer = document.querySelector('#ewnLeftMenu');
    if (configuration.useWebComponents) {
        customElements.define('lni-ewn-secondary-nav', LniSecondaryNav);
        secondaryNavElement = document.createElement('lni-ewn-secondary-nav');
        secondaryNavElement.setAttribute(
            'ia-location',
            configuration.iaLocation,
        );

        ewnLeftMenuContainer.appendChild(secondaryNavElement);
    } else {
        const secondaryNavElement = document.createElement('IFRAME');
        secondaryNavElement.id = 'ewn-secondary-nav-iframe';
        ewnLeftMenuContainer.appendChild(secondaryNavElement);
        secondaryNavIframe(secondaryNavElement, configuration);
    }
};