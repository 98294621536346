import initializeOneLni from '../../core/lifecycle/initializeOneLni';
import createBodyContainer from '../../core/html-building/createBodyContainer';
import addOneLniScripts from '../../core/html-building/addOneLniScripts';

export default class LniEwnHeader extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({
            mode: 'open',
        });
        this.shadow = shadow;

        createBodyContainer(this.shadow, 'ewn-secondary-nav', []);
    }

    connectedCallback() {
        this.initialize(this);
    }

    initialize() {
        const oneLniElements = addOneLniScripts(this.shadow, true);
        const container = createBodyContainer(this.shadow, 'ewn-secondary-nav', [
            'lni-side-nav',
        ]);

        const iaLocation = this.getAttribute('ia-location');
        oneLniElements.scriptElement.addEventListener('load', () => {
            initializeOneLni(container, null, iaLocation);
        });
    }
}