
export default (parentElement, children) => {
    let idCounter = 0;

    children.forEach(child => {
        const childElement = document.createElement(child);
        childElement.id = `${parentElement.id}-${child}_${idCounter++}`;

        parentElement.appendChild(childElement);
    });
};