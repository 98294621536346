// Build the EWN DOM structure.  Much of this structure is expected by contained apps
// in one fashion or another, primarily their styles attach to these classes and ids
export default configuration => {
    const fullWidthContainer = document.createElement('DIV');
    fullWidthContainer.id = 'ewnContainer';
    fullWidthContainer.classList.add('full-width-ewn-container');
    if (configuration.appPaths) {
        // put the paths from each environment for this app
        // into the classlist.  Then we can use a css selector that
        // will work everywhere to modify the app if needed.
        configuration.appPaths.forEach(path => {
            fullWidthContainer.classList.add(path);
        });
    }

    // This is added because the contractor debarment app depends
    // on the count of form elements in the dom.  The new EWN has
    // the form element in a web component and so it isn't counted
    // and that app breaks.
    const formNode = document.createElement('FORM');
    formNode.id = 'emptyForm';
    fullWidthContainer.appendChild(formNode);

    const headerContainer = document.createElement('DIV');
    headerContainer.id = 'ewnHeaderContainer';
    fullWidthContainer.appendChild(headerContainer);

    const hostApplicationContainer = document.createElement('DIV');
    hostApplicationContainer.id = 'hostContainer';
    hostApplicationContainer.classList.add('container');

    const clearFix = document.createElement('DIV');
    clearFix.style.clear = 'both';
    hostApplicationContainer.appendChild(clearFix);

    const leftMenuContainer = document.createElement('DIV');
    leftMenuContainer.id = 'ewnLeftMenu';
    hostApplicationContainer.appendChild(leftMenuContainer);

    const skipLink = document.createElement('A');
    skipLink.name = 'ContentArea';
    skipLink.tabIndex = 0;
    hostApplicationContainer.appendChild(skipLink);

    const hostApplication = document.createElement('DIV');
    hostApplication.id = 'appBody';
    hostApplication.classList.add('ewn3body');
    if (configuration.show.secondaryNavigation) {
        hostApplication.classList.add('bodyHasLeftMenu');
        hostApplicationContainer.classList.add('has-left-menu');
    }

    hostApplicationContainer.appendChild(hostApplication);

    const clearFix2 = document.createElement('DIV');
    clearFix2.style.clear = 'both';
    hostApplicationContainer.appendChild(clearFix2);

    fullWidthContainer.appendChild(hostApplicationContainer);

    const footerContainer = document.createElement('DIV');
    footerContainer.id = 'footer';
    fullWidthContainer.appendChild(footerContainer);

    const legalFooterContainer = document.createElement('DIV');
    legalFooterContainer.id = 'legalFoot';
    fullWidthContainer.appendChild(legalFooterContainer);

    document.body.appendChild(fullWidthContainer);

    const hostBody = document.querySelector('BODY');
    const hostBodyIdElement = hostBody.querySelector('#body');

    // Some apps have an element with a id of body inside of them.  If they do,
    // we can't add another.  If they don't, we have to add one.  That is the reason
    // we have to create the containing div with id of #appBody.
    let documentHostApplication = document.querySelector('#appBody');
    if (!hostBodyIdElement) {
        const newHostBodyIdElement = document.createElement('DIV');
        newHostBodyIdElement.id = 'body';
        documentHostApplication.appendChild(newHostBodyIdElement);
        documentHostApplication = newHostBodyIdElement;
    }

    // Take all of the elements that are in the body of the contained app and put
    // them into the new container div within our created DOM structure.  "appendChild"
    // will remove them from their existing place in the DOM when it moves them to the
    // new place.
    if (hostBody.children.length > 0) {
        const childrenArray = Array.from(hostBody.children);
        childrenArray.forEach(node => {
            if (node.id !== 'ewnContainer') {
                documentHostApplication.appendChild(node);
            }
        });
    }
};