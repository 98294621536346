import initializeOneLni from '../../core/lifecycle/initializeOneLni';
import createBodyContainer from '../../core/html-building/createBodyContainer';
import addOneLniScripts from '../../core/html-building/addOneLniScripts';

export default class LniEwnHeader extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({
            mode: 'open',
        });
        this.shadow = shadow;
    }

    connectedCallback() {
        this.initializeHeader(this);
    }

    initializeHeader() {
        const oneLniElements = addOneLniScripts(this.shadow, true);
        let styleElement = document.createElement('style');
        styleElement.innerHTML = `
            .lni-c-site-header__container {
                box-shadow: none !important;
            }

            @media screen and (min-width: 715px) {
                .lni-c-mega-menu__section-link {
                    width: unset !important;
                    z-index: 10;
                }

                .lni-c-mega-menu__item.lni-c-mega-menu__toggle.--level2 {
                    width: 95%
                }
            }
        `;

        this.shadow.appendChild(styleElement);

        let container;
        const experience = this.getAttribute('experience');
        const breadcrumb = this.getAttribute('breadcrumb');
        const iaLocation = this.getAttribute('ia-location');
        const appTitle = this.getAttribute('app-title');
        const appNames = this.getAttribute('app-names');

        let children = [];
        if (experience === 'simple') {
            children.push('lni-header-simple');
        } else if (experience === 'authenticated') {
            children.push('lni-header-secure');
        } else {
            children.push('lni-header-anonymous');
        }

        if (breadcrumb === 'true') {
            children.push('lni-breadcrumb');
        }
        container = createBodyContainer(this.shadow, 'ewn-header', children);

        if (appNames) {
            const appNamesList = appNames.split(',');
            appNamesList.forEach(appName => {
                if (appName) {
                    container.classList.add(appName);
                }
            });
        }

        if (experience === 'simple') {
            if (appTitle) {
                let component = container.querySelector('lni-header-simple');
                component.setAttribute('app-name', appTitle);
            } else {
                //TODO: This should be fixed in the simple header component itself.
                //Missing class names should not have text on the header
                let component = container.querySelector('lni-header-simple');
                component.setAttribute('app-name', '');
            }
        }

        oneLniElements.scriptElement.addEventListener('load', () => {
            initializeOneLni(container, null, iaLocation);
        });
    }
}