import createIframeContainer from '../../core/html-building/createIframeContainer';
import addOneLniScripts from '../../core/html-building/addOneLniScripts';
import compileIframe from '../../core/html-building/compileIframe';


export default (iframeElement, configuration) => {
    const parentElement = iframeElement.contentDocument.body;

    addOneLniScripts(parentElement, true);

    const container = createIframeContainer(parentElement, 'ewn-secondary-nav', [
        'lni-side-nav',
    ]);

    return compileIframe(
        iframeElement,
        container,
        configuration.iaLocation
    );
};