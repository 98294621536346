import environment from '../../environment/environment';
import addStyleLink from '../addStyleLink';
import addInlineStyle from './addInlineStyle';
import addLegacyStyleSheets from './addLegacyStylesheets';

export default configuration => {
    const head = document.head;

    addStyleLink(head, {
        type: 'stylesheet',
        href: 'https://fonts.googleapis.com/css?family=Libre+Franklin:400,400i,700,700i|Roboto:400,500,700,900',
    });

    addInlineStyle(head, configuration);

    if (configuration.compatibilityMode) {
        addLegacyStyleSheets(head, configuration);

        addStyleLink(head, {
            type: 'stylesheet',
            href: `${environment.ewn.style}`,
        });
    }
};